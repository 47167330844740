import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  InterceptorProps,
  InterceptorStateProps,
  InternalConfig,
  InternalInvokers,
  InvalidTokenStates,
  JWTDecoded,
  Options,
} from "./_model";
import { checkOptions } from "./helpers/check-options";
import { initToken } from "./helpers/init-token";
import { decodeJWT, getJWT } from "./helpers/decode-jwt";
import { attachRequestInterceptor } from "./attach-request-interceptor";
import { KEYS, error, warn } from "./const";
import { checkEndpointURL } from "./helpers/check-endpoint-url";
import { checkAuthConfig } from "./helpers/check-auth-config";
import { createAxiosInstance } from "./create-axios-instance";
import { attachResponseInterceptor } from "./attach-response-interceptor";
import { date } from "@qundus.tc/agnostic.helpers";
import { initAccessDenied } from "./helpers/init-access-denied";

// TODO: try launching localtunnel into development when the first request is made :()
export function createApiController<
  JWT extends JWTDecoded = JWTDecoded,
  Token = any,
  TokenRevalidate = any,
  TokenRemove = any,
  /////
  CustomEventsForAuth extends Record<string, Function> = any
>(_options: Options<JWT, Token, TokenRevalidate, TokenRemove, CustomEventsForAuth>) {
  //
  const options = checkOptions(_options as any);
  let _internal_config: InternalConfig = {
    // defaults
    first_auth_request: true,
    httpOnlyCookieTempSession: false,
    access_denied: initAccessDenied(),
  };
  let _token_response: AxiosResponse<Token> = initToken(options, _internal_config);
  let _jwt_decoded: JWT = decodeJWT(_token_response as any, options) as any;
  let _axios = createAxiosInstance(options);
  // let _is_initilized = false;

  ////
  // inits
  const _internal_invokers: InternalInvokers = {
    clearToken(props: { res?: AxiosResponse; err?: any; skipUpdate?: boolean }) {
      let { res, err } = props;
      if (!this.tokenExist() && !err) {
        err = "unknown logout reason";
      }
      if (!props?.skipUpdate) {
        options?.auth?.triggers?.onTokenRemove?.({ jwt: _jwt_decoded, res, err, internalInvokers: _internal_invokers });
      }
      _token_response = undefined;
      _jwt_decoded = undefined;
      //
      localStorage?.removeItem?.(KEYS.LOCALSTORAGE_TOKEN);
      if (options.auth?.manulTokenExpiration) {
        localStorage?.removeItem?.(KEYS.LOCALSTORAGE_TOKEN_MANUAL_EXPIRE);
      }
    },
    tokenExist() {
      return _jwt_decoded !== undefined && _jwt_decoded !== null;
    },
  };
  options.triggers?.onInitAccessDenied?.(_internal_config.access_denied);
  const _custom_events = {
    auth: options.auth?.customEvents?.({
      getJWT: () => _jwt_decoded,
      getRes: () => _token_response,
      internalInvokers: _internal_invokers,
    }) as ReturnType<typeof _options.auth.customEvents>,
  };
  ////

  function revalidateAuthedCall(props: Parameters<InterceptorProps["revalidateAuthedCall"]>[0]) {
    const { state, res, err } = props;
    if (!state.auth.is_auth) {
      return;
    }
    let defaults = options.auth?.defaults;
    try {
      // first check nn auth based endpoints
      if (!state.auth.endpoints.is_auth_endpoint) {
        if (err) {
          const err_status = err?.response?.status;
          if (!options.auth.defaults?.ignoreUnauthorizedAccessStatusCodes?.includes(err_status)) {
            const status = options.auth?.triggers?.onUnauthorizedAccess?.({
              jwt: _jwt_decoded,
              res,
              err,
              defaults,
              internalInvokers: _internal_invokers,
              state,
            });
            if (status !== undefined && status !== null) {
              onInvalidTokenStatus({ status: status as any, res, err, options });
              return;
            }
          }
          throw new Error(err);
        }
        return;
      }
      // then check for errors
      if (err) {
        // special case: http-only logging out but has already logged out which will cause no triggers fired
        if (state.auth.endpoints.is_token_remove) {
          _internal_invokers.clearToken({ res, err });
          return;
        }
        const status = options.auth?.triggers?.onInvalidatedToken?.({ res, err, defaults, state });
        if (status !== undefined && status !== null) {
          onInvalidTokenStatus({ status: status as any, res, err, options });
          return;
        }
        throw new Error(err);
      }
      // alast treat each endpoint based on auth mode
      if (options.auth?.mode === "http-only-cookie") {
        if (state.auth.endpoints.is_token) {
          setTokenResponse(res);
          return;
        } else if (state.auth.endpoints.is_token_remove) {
          _internal_invokers.clearToken({ res, err });
          return;
        }
        const jwt = options.auth?.events?.getTokenRevalidate?.({ res, err });
        const token_exist = _internal_invokers.tokenExist();
        _token_response = res;
        _jwt_decoded = getJWT(jwt, options, res) as any;
        if (token_exist) {
          options.auth?.triggers?.onTokenObtained({ jwt: _jwt_decoded, res, internalInvokers: _internal_invokers });
        } else {
          options.auth?.triggers?.onTokenUpdate({ jwt: _jwt_decoded, res, internalInvokers: _internal_invokers });
        }
      } else if (options.auth?.mode === "localstorage") {
        const token_resonse = options.auth?.events?.getTokenRevalidate?.({ res, err });
        if (token_resonse === undefined || token_resonse === null) {
          const status = options.auth?.triggers?.onInvalidatedToken?.({ res, err, defaults, state });
          if (status !== undefined && status !== null) {
            onInvalidTokenStatus({ status: status as any, res, err, options });
            return;
          }
          error("options.auth?.events?.onTokenRevalidate returned a undefined or null object");
          // we can't have a null _token_response unlike in http-only-cookie
          throw new Error(
            `AxiosResponse: token has been refreshed properly from remote api but options.auth?.events?.onTokenRefreshed 
            returned a null or undefined object, this will cause new token to not be stored and it burns the old refresh token. 
            and since i have no way to know your tokenRefresh response body i can't store anything so i have to log user out.`
          );
        }
        setTokenResponse(token_resonse);
      }
    } catch (err: any) {
      onInvalidTokenStatus({ status: defaults.invalidToken, res, err, options });
    }
  }
  function onInvalidTokenStatus(props: { status: InvalidTokenStates; res: any; err: any; options: Options }) {
    const { res, err, status, options } = props;
    // const defaults = options.auth.defaults
    if (status === "logout") {
      if (options.auth?.mode === "http-only-cookie") {
        if (_internal_invokers.tokenExist()) {
          const config = checkAuthConfig(undefined);
          _axios.get(options.auth?.endpoints?.getTokenRemove.url, config);
          return;
        }
      }
      // options.auth?.events?.getTokenRevalidate?.({ res, err });
      _internal_invokers.clearToken({ res, err });
    } else if (status === "force token set") {
      setTokenResponse(res);
    }
  }
  function setTokenResponse(res: AxiosResponse<Token>, temp?: true) {
    if (res === undefined || res === null) {
      error("jwt object is undefined or null, cannot set jwt for axios instance!");
      return;
    }
    const token_exist = _internal_invokers.tokenExist();
    _token_response = res;
    _jwt_decoded = decodeJWT(res as any, options) as any;
    // _internal_config.initizlized = true;
    if (token_exist) {
      options.auth?.triggers?.onTokenUpdate?.({ jwt: _jwt_decoded, res, internalInvokers: _internal_invokers });
    } else {
      options.auth?.triggers?.onTokenObtained?.({ jwt: _jwt_decoded, res, internalInvokers: _internal_invokers });
    }

    if (temp === true) {
      if (options?.auth?.mode === "http-only-cookie") {
        warn("activating session-only token under http-only-cookie protocol");
      } else {
        warn("activating session token");
      }
      return;
    }
    if (!_internal_config.access_denied.localstorageDenied) {
      if (options.auth?.manulTokenExpiration) {
        const unit = options.auth?.manulTokenExpiration.unit;
        const period = options.auth?.manulTokenExpiration.period;
        const new_date = date.getDateFrom(new Date(), unit, period);
        localStorage.setItem(KEYS.LOCALSTORAGE_TOKEN_MANUAL_EXPIRE, JSON.stringify(new_date));
        _internal_config.manualTokenExpire = new_date;
      }
      if (options?.auth?.mode === "localstorage") {
        localStorage?.setItem?.(KEYS.LOCALSTORAGE_TOKEN, JSON.stringify(res));
        return;
      }
    } else {
      warn("AxiosInstance: localstorage was blocked by user, using one time session");
      _internal_config.httpOnlyCookieTempSession = true;
    }
  }

  const interceptor_props: InterceptorProps = {
    instance: _axios,
    options,
    internalInvokers: _internal_invokers,
    getTokenResponse: () => _token_response,
    getTokenDecoded: () => _jwt_decoded,
    getInternalConfig: () => _internal_config,
    updateInternalConfig: (conf) => (_internal_config = { ..._internal_config, ...conf }),
    revalidateAuthedCall: revalidateAuthedCall,
    getConfigState: (config) => {
      const is_token = config?.url === options?.auth?.endpoints?.postToken?.url;
      let is_token_revalidate = undefined;
      let is_token_remove = undefined;
      if (options.auth?.mode === "http-only-cookie") {
        is_token_revalidate = config?.url === options?.auth?.endpoints?.getTokenRevalidate?.url;
        is_token_remove = config?.url === options?.auth?.endpoints?.getTokenRemove?.url;
      } else if (options.auth?.mode === "localstorage") {
        is_token_revalidate = config?.url === options?.auth?.endpoints?.postTokenRevalidate?.url;
      }
      return {
        auth: {
          is_auth: config?.[KEYS.CONFIG.AUTH],
          is_internal_request: config?.[KEYS.CONFIG.INTERNAL_REQUEST],
          endpoints: {
            is_auth_endpoint: is_token || is_token_revalidate || is_token_remove,
            is_token,
            is_token_revalidate,
            is_token_remove,
          },
        },
      };
    },
  };

  attachRequestInterceptor(interceptor_props);
  attachResponseInterceptor(interceptor_props);
  return {
    auth: {
      events: _custom_events.auth,
      get mode() {
        return options.auth?.mode;
      },
      get token(): Token {
        return _token_response.data;
      },
      get jwt() {
        return _jwt_decoded;
      },
      get tokenExist() {
        return _internal_invokers.tokenExist;
      },
      get setTokenResponse() {
        return setTokenResponse;
      },
      get login() {
        return async (data: any) => {
          const config = checkAuthConfig(undefined);
          return _axios.post(options.auth?.endpoints?.postToken?.url, data, config);
        };
      },
      get logout() {
        return async () => {
          if (options.auth?.mode === "http-only-cookie") {
            const config = checkAuthConfig(undefined);
            return _axios.get(options.auth?.endpoints?.getTokenRemove?.url, config);
          }
          _internal_invokers.clearToken({ res: { data: { meessage: "logging out" } } as any });
          return Promise.resolve();
        };
      },
      get checkToken() {
        return async () => {
          if (options.auth?.mode === "http-only-cookie") {
            if (_internal_invokers.tokenExist()) {
              return Promise.resolve(_token_response);
            }
            const config = checkAuthConfig(undefined);
            return _axios.get(options.auth?.endpoints?.getTokenRevalidate?.url, config);
          }
          if (this.tokenExist()) {
            return Promise.resolve(this.jwt);
          }
          _internal_invokers.clearToken({ err: "token not found, should logout!" });
          return Promise.reject();
        };
      },
    },
    get<T = any>(url: string, config?: AxiosRequestConfig) {
      url = checkEndpointURL(url);
      return _axios.get<T>(url, config);
    },
    getAuth<T = any>(url: string, config?: AxiosRequestConfig) {
      config = checkAuthConfig(config);
      return this.get<T>(url, config);
    },
    post<T = any>(url: string, data: unknown, config?: AxiosRequestConfig) {
      url = checkEndpointURL(url);
      return _axios.post<T>(url, data, config);
    },
    postAuth<T = any>(url: string, data: unknown, config?: AxiosRequestConfig) {
      config = checkAuthConfig(config);
      return this.post<T>(url, data, config);
    },
    put<T = any>(url: string, data: unknown, config?: AxiosRequestConfig) {
      url = checkEndpointURL(url);
      return _axios.put<T>(url, data, config);
    },
    putAuth<T = any>(url: string, data: unknown, config?: AxiosRequestConfig) {
      config = checkAuthConfig(config);
      return this.put<T>(url, data, config);
    },
    patch<T = any>(url: string, data: unknown, config?: AxiosRequestConfig) {
      url = checkEndpointURL(url);
      return _axios.patch<T>(url, data, config);
    },
    patchAuth<T = any>(url: string, data: unknown, config?: AxiosRequestConfig) {
      config = checkAuthConfig(config);
      return this.patch<T>(url, data, config);
    },
    del<T = any>(url: string, config?: AxiosRequestConfig) {
      url = checkEndpointURL(url);
      return _axios.delete<T>(url, config);
    },
    delAuth<T = any>(url: string, config?: AxiosRequestConfig) {
      config = checkAuthConfig(config);
      return this.del<T>(url, config);
    },
  };
}
